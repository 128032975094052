import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  Fieldset,
  NumberInput,
  Group,
  Button,
} from '@mantine/core';
import { GENDER } from '../hooks/useFormData';

export const Dick = ({
  onNext,
  onBack,
  gender,
  penis,
  setPenis,
}) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    onNext();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Fieldset mb="md" legend={t('dick.title')}>
        <NumberInput
          id="penis"
          {...register('penis', {
            required: true,
            min: 1,
            valueAsNumber: true,
          })}
          value={penis}
          onChange={(value) => setPenis(value)}
          error={errors.penis ? t('validationError.empty') : undefined}
          label={
            gender === GENDER.male
            ? t('dick.male.label')
            : t('dick.female.label')
          }
          mb="md"
          required
        />
      </Fieldset>

      <Group justify="center">
        <Button variant="light" onClick={onBack}>
          {t('cta.back')}
        </Button>
        <Button type="submit">
          {t('cta.next')}
        </Button>
      </Group>
    </form>
  );
};
