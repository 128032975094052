import { useState } from 'react';

const INITIAL_STEP = 0;
const MAX_STEPS_WITH_COMPLETED_STEP = 3;

export const useFormWizard = () => {
  const [active, setActive] = useState(INITIAL_STEP);
  const [highestStepVisited, setHighestStepVisited] = useState(active);

  const handleStepChange = (nextStep) => {
    const isOutOfBounds =
      nextStep > MAX_STEPS_WITH_COMPLETED_STEP || nextStep < 0;

    if (isOutOfBounds) {
      return;
    }

    setActive(nextStep);
    setHighestStepVisited((hSC) => Math.max(hSC, nextStep));
  };

  const shouldAllowSelectStep = (step) =>
    highestStepVisited >= step && active !== step;

  return { active, handleStepChange, shouldAllowSelectStep };
};
