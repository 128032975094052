import React from 'react';
import { useTranslation } from 'react-i18next';

import '@mantine/core/styles.css';
import { Container, Text, Flex } from '@mantine/core';

import CONFIG from './config';
import LocaleSwitcher from './i18n/LocaleSwitcher';
import { Wizard } from './components/Wizard';
import logo from './images/logo.png';

export default function App() {
  const { t } = useTranslation();

  return (
    <Container size="xs">
      <Flex
        mt="md"
        mb="xl"
        gap="md"
        direction="row"
        align="center"
        justify="space-between"
      >
        <img src={logo} width={48} height={48} alt={`${CONFIG.brand} logo`} />
        <LocaleSwitcher />
      </Flex>

      <Wizard />

      <Text size="sm" fs="italic" ta="center" mt="md" mb="md">
        {t('footer.copyright', {
          copyYear: `\u00A9 ${new Date().getFullYear()}`,
          brand: CONFIG.brand
        })}
      </Text>
    </Container>
  );
}
