import { useState } from 'react';

export const GENDER = {
  female: 'FEMALE',
  male: 'MALE',
};

export const SEX_TEMPO = {
  slow: 'SLOW',
  normal: 'NORMAL',
  fast: 'FAST',
};

export const useFormData = () => {
  // gender selection
  const [gender, setGender] = useState(GENDER.female);

  // main form
  const [penis, setPenis] = useState('');
  const [tempo, setTempo] = useState(SEX_TEMPO.normal);
  const [duration, setDuration] = useState('');
  const [dailyAmount, setDailyAmount] = useState('');
  const [weeklyAmount, setWeeklyAmount] = useState('');
  const [monthsTotal, setMonthsTotal] = useState('');
  const [submitting, setSubmitting ] = useState(false);

  // result
  const [score, setScore] = useState(0);

  const submitFormData = (payload) => {
    console.log(payload);

    setSubmitting(true);

    // fake API network request delay
    return new Promise(resolve => setTimeout(() => {
      setScore(9);
      setSubmitting(false);
      resolve();
    }, 2000));
  };

  const resetFormData = () => {
    setGender(GENDER.female);
    setPenis('');
    setTempo(SEX_TEMPO.normal);
    setDuration('');
    setDailyAmount('');
    setWeeklyAmount('');
    setMonthsTotal('');
  };

  return {
    gender,
    setGender,
    penis,
    setPenis,
    tempo,
    setTempo,
    duration,
    setDuration,
    dailyAmount,
    setDailyAmount,
    weeklyAmount,
    setWeeklyAmount,
    monthsTotal,
    setMonthsTotal,
    score,
    submitting,
    submitFormData,
    resetFormData,
  };
};
