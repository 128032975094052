import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stepper } from '@mantine/core';
import { useFormWizard } from '../hooks/useFormWizard';
import { useFormData } from '../hooks/useFormData';
import { Gender } from './Gender';
import { Dick } from './Dick';
import { SexDetails } from './SexDetails';
import { Result } from './Result';

export const Wizard = () => {
  const { t } = useTranslation();

  const {
    active,
    handleStepChange,
    shouldAllowSelectStep,
  } = useFormWizard();

  const {
    gender,
    setGender,
    penis,
    setPenis,
    tempo,
    setTempo,
    duration,
    setDuration,
    dailyAmount,
    setDailyAmount,
    weeklyAmount,
    setWeeklyAmount,
    monthsTotal,
    setMonthsTotal,
    score,
    submitting,
    submitFormData,
    resetFormData,
  } = useFormData();

  const calculate = async () => {
    try {
      await submitFormData({
        gender,
        penis,
        tempo,
        duration,
        dailyAmount,
        weeklyAmount,
        monthsTotal,
      });

      handleStepChange(active + 1);
    } catch(err) {
      console.error(err);
    }
  }

  const resetForm = () => {
    resetFormData();
    handleStepChange(0);
  }

  return (
    <>
      <Stepper
        size="xs"
        active={active}
        onStepClick={null}
        orientation="horizontal"
      >
        <Stepper.Step label={t('stepper.firstStepLabel')} allowStepSelect={shouldAllowSelectStep(0)}>
          <Gender
            onNext={() => handleStepChange(active + 1)}
            gender={gender}
            setGender={setGender}
          />
        </Stepper.Step>
        <Stepper.Step label={t('stepper.secondStepLabel')} allowStepSelect={shouldAllowSelectStep(1)}>
          <Dick
            onNext={() => handleStepChange(active + 1)}
            onBack={() => handleStepChange(active - 1)}
            gender={gender}
            penis={penis}
            setPenis={setPenis}
          />
        </Stepper.Step>
        <Stepper.Step label={t('stepper.thirdStepLabel')} allowStepSelect={shouldAllowSelectStep(1)}>
          <SexDetails
            onNext={calculate}
            onBack={() => handleStepChange(active - 1)}
            tempo={tempo}
            setTempo={setTempo}
            duration={duration}
            setDuration={setDuration}
            dailyAmount={dailyAmount}
            setDailyAmount={setDailyAmount}
            weeklyAmount={weeklyAmount}
            setWeeklyAmount={setWeeklyAmount}
            monthsTotal={monthsTotal}
            setMonthsTotal={setMonthsTotal}
            isSubmitting={submitting}
          />
        </Stepper.Step>
        <Stepper.Completed>
          <Result score={score} onNext={resetForm} />
        </Stepper.Completed>
      </Stepper>
    </>
  );
};
