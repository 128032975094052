import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { createTheme, MantineProvider } from '@mantine/core';
import './i18n/config.js';
import App from './App';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const theme = createTheme({
  primaryColor: 'grape',
});

root.render(
  <StrictMode>
    <React.Suspense fallback={<div>Loading...</div>}>
      <MantineProvider theme={theme}>
        <App />
      </MantineProvider>
    </React.Suspense>
  </StrictMode>
);
