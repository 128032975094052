import { useTranslation } from 'react-i18next';
import { Select } from '@mantine/core';
import { APP_LANGUAGE } from './config';

export default function LocaleSwitcher() {
    const { i18n, t } = useTranslation();
    const data = Object.entries(APP_LANGUAGE).map(([code, name]) => ({
        value: code,
        label: name,
    }));

    return (
        <Select
            aria-label={t('localeSwitcher.selectLabel')}
            data={data}
            value={i18n.resolvedLanguage}
            onChange={(_value) => i18n.changeLanguage(_value)}
            w={120}
        />
    );
}