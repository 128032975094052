import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { Fieldset, Radio, Stack, Button, Group } from '@mantine/core';
import { GENDER } from '../hooks/useFormData';

export const Gender = ({ onNext, gender, setGender }) => {
  const { t } = useTranslation();
  const { control, register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    onNext();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset mb="md" legend={t('gender.title')}>
        <Stack>
          <Controller
            name="gender"
            control={control}
            render={({ field }) => {
              return (
                <Radio
                  {...field}
                  id="female"
                  value={GENDER.female}
                  label={t('gender.female.label')}
                  {...register('gender')}
                  defaultChecked={gender === GENDER.female}
                  onChange={(e) => setGender(e.target.value)}
                />
              );
            }}
          />
          <Controller
            name="gender"
            control={control}
            render={({ field }) => {
              return (
                <Radio
                  {...field}
                  id="male"
                  value={GENDER.male}
                  label={t('gender.male.label')}
                  {...register('gender')}
                  defaultChecked={gender === GENDER.male}
                  onChange={(e) => setGender(e.target.value)}
                />
              );
            }}
          />
        </Stack>
      </Fieldset>
      <Group justify="center" mt="md">
        <Button type="submit">
          {t('cta.next')}
        </Button>
      </Group>
    </form>
  );
};
