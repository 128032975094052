import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
    Alert,
    Fieldset,
    Flex,
    NumberInput,
    Group,
    Button,
    Select,
} from '@mantine/core';
import { SEX_TEMPO } from '../hooks/useFormData';
import { isObjEmpty } from '../utils';

export const SexDetails = ({
    onNext,
    onBack,
    tempo,
    setTempo,
    duration,
    setDuration,
    dailyAmount,
    setDailyAmount,
    weeklyAmount,
    setWeeklyAmount,
    monthsTotal,
    setMonthsTotal,
    isSubmitting = false,
}) => {
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        console.log(data);
        onNext();
    };

    const SEX_TEMPO_OPTIONS = [
        { value: SEX_TEMPO.slow, label: t('sex.tempo.slow') },
        { value: SEX_TEMPO.normal, label: t('sex.tempo.normal') },
        { value: SEX_TEMPO.fast, label: t('sex.tempo.fast') }
    ];

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            {isObjEmpty(errors) ? null : (
                <Alert variant="filled" color="red" title={t('sex.error.title')} mb="md">
                    {t('sex.error.description')}
                </Alert>
            )}

            <Fieldset mb="md" legend={t('sex.tempo.legend')}>
                <Select
                    label={t('sex.tempo.label')}
                    data={SEX_TEMPO_OPTIONS}
                    required
                    onChange={(value) => setTempo(value)}
                    defaultValue={tempo}
                    disabled={isSubmitting}
                />
            </Fieldset>

            <Fieldset mb="md" legend={t('sex.durationAndFrequency.legend')}>
                <NumberInput
                    id="duration"
                    {...register('duration', {
                        required: true,
                        min: 0.5,
                        valueAsNumber: true,
                    })}
                    value={duration}
                    onChange={(value) => setDuration(value)}
                    error={errors.duration ? t('validationError.empty') : undefined}
                    label={t('sex.durationAndFrequency.duration.label')}
                    mb="md"
                    required
                    disabled={isSubmitting}
                />

                <Flex
                    direction={{ base: 'column', xs: 'row' }}
                    gap={{ base: 'xs', xs: 'md' }}
                    justify={{ sm: 'center' }}
                    mb="md"
                    w="100%"
                >
                    <NumberInput
                        id="dailyAmount"
                        {...register('dailyAmount', {
                            required: true,
                            min: 1,
                            valueAsNumber: true,
                        })}
                        value={dailyAmount}
                        onChange={(value) => setDailyAmount(value)}
                        error={errors.dailyAmount ? t('validationError.empty') : undefined}
                        label={t('sex.durationAndFrequency.dailyAmount.label')}
                        w="100%"
                        required
                        disabled={isSubmitting}
                    />
                    <NumberInput
                        id="weeklyAmount"
                        {...register('weeklyAmount', {
                            required: true,
                            min: 1,
                            valueAsNumber: true,
                        })}
                        value={weeklyAmount}
                        onChange={(value) => setWeeklyAmount(value)}
                        error={errors.weeklyAmount ? t('validationError.empty') : undefined}
                        label={t('sex.durationAndFrequency.weeklyAmount.label')}
                        w="100%"
                        required
                        disabled={isSubmitting}
                    />
                </Flex>

                <NumberInput
                    id="monthsTotal"
                    {...register('monthsTotal', { required: true, min: 0.25, valueAsNumber: true })}
                    value={monthsTotal}
                    defaultValue="0"
                    onChange={(value) => setMonthsTotal(value)}
                    error={errors.monthsTotal ? t('validationError.empty') : undefined}
                    label={t('sex.durationAndFrequency.monthsTotal.label')}
                    required
                    disabled={isSubmitting}
                />
            </Fieldset>

            <Group justify="center" mt="md">
                <Button variant="light" onClick={onBack} disabled={isSubmitting}>
                    {t('cta.back')}
                </Button>
                <Button type="submit" loading={isSubmitting}>
                    {t('cta.calculate')}
                </Button>
            </Group>
        </form>
    );
};
