import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Group, Fieldset } from '@mantine/core';

export const Result = ({ score, onNext }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Alert variant="outline" color="teal" title={t('result.info.title')} mb="md">
        {t('result.info.description')}
      </Alert>

      <Fieldset mb="md" legend={t('result.title')}>
        {score}
      </Fieldset>

      <Group justify="center" mt="md">
        <Button type="reset" onClick={onNext}>
          {t('cta.reset')}
        </Button>
      </Group>
    </div>
  );
};
